<template>
    <v-radio-group 
        v-if="items.length <= 25" 
        v-model="calculatedValue" 
        :rules="selectOptionRules" 
        :label="label"
        >
      <v-radio v-for="val in items" :key="val"
        :value="itemValue ? val[itemValue] : val"
        :label="itemText ? val[itemText] : val"
        />
    </v-radio-group>   
    <v-autocomplete
        v-else
        v-model="calculatedValue"
        :items="items"
        :rules="selectOptionRules"
        :label="label"
        :item-text="itemText"
        :item-value="itemValue"
        /> 
</template>

<script>

export default {
  name: "RadioButtonOrAutocomplete",
  components: {
  },
  props: {
    value: { type: String, default: null },
    label: String,
    items: Array,
    itemText: { type: String, default: null },
    itemValue: { type: String, default: null },
  },
  async mounted() {
  },   
  computed: {
    calculatedValue:{
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value);
      }
    },  
    selectOptionRules() {
      return [
        v => !!v || this.$t('shared.required-option'),
      ];
    },
  }
};
</script>

<style lang="scss">
</style>