<template>
  <div v-if="field && field.label">
    <h4>{{field.label}}</h4>
    <p v-if="field.hint" class="text-muted pb_0 mb_0">{{field.hint}}</p>
    <RadioButtonOrAutocomplete 
      v-if="field.type == 'TEAM'" 
      v-model="calculatedValue" 
      :items="teams" 
      :rules="selectOptionRules"
      item-text="name"
      item-value="id"
      class="pt-0 mt-2"
      />
    <!-- <v-radio-group v-if="field.type == 'TEAM'" v-model="calculatedValue" :rules="selectOptionRules" class="pt-0 mt-2">
      <v-radio v-for="val in teams" :key="val.id"
        :value="val.id"
        :label="val.name"
        />
    </v-radio-group> -->
    <RadioButtonOrAutocomplete 
      v-else-if="field.options && field.options.length" 
      v-model="calculatedValue" 
      :items="field.options" 
      class="pt-0 mt-2"
      />
    <!-- <v-radio-group v-else-if="field.options && field.options.length" v-model="calculatedValue" :rules="selectOptionRules" class="pt-0 mt-2">
      <v-radio v-for="val in field.options" :key="val"
        :value="val"
        :label="val"
        :rules="selectOptionRules"
        />
    </v-radio-group> -->
    <v-text-field 
      v-else
      v-model="calculatedValue"
      :label="field.label"
      :rules="selectOptionRules"
      />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import RadioButtonOrAutocomplete from "@/components/generic/RadioButtonOrAutocomplete.vue"
import groupService from "@/services/groupService";

export default {
  name: "RegFieldInput",
  components: {
    RadioButtonOrAutocomplete,
  },
  props: {
    value: { type: String, default: null },
    eventId: String,
    field: { type: Object, default: null },
  },
  data: function() {
    return {
      teams: [],
    };
  },
  async mounted() {
    EventBus.$on('login-state-change', async user => {
      await this.maybeGetTeamNames();
    });
    await this.maybeGetTeamNames();
  },
  methods: {

    async maybeGetTeamNames() {
      console.log('Loading team names?');
      if (this.user && this.teams.length == 0 && this.field && this.field.type == 'TEAM') {
        this.teams = (await groupService.getGroupsInEvent(this.eventId, this.field.team_category)).data.data;
      }
    },      
  },
  computed: {
    calculatedValue:{
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value);
      }
    },  
    selectOptionRules() {
      return [
        v => !!v || this.$t('shared.required-option'),
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
      
  }
};
</script>

<style lang="scss">
</style>